import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from 'src/environments/environment';

import { ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountNewModule } from './v2/account/account.module';
import { DefaultModule } from './v2/default/default.module';
import { NewFooterComponent } from './v2/footer/footer.component';
import { HeaderComponent } from './v2/header/header.component';
import { NavbarComponent } from './v2/navbar/navbar.component';
import { PageLayoutComponent } from './v2/page-layout/page-layout.component';
import { SharedModuleV2 } from './v2/shared/shared.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

declare global {
  interface Window {
      bootstrap:any;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    PageLayoutComponent,
    HeaderComponent,
    NewFooterComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModuleV2,
    ReactiveFormsModule,
    AccountNewModule,
    DefaultModule,
    CarouselModule,
    NoopAnimationsModule
  ],
  providers: [
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
       showDialog: false,
      }),
     },
     {
      provide: Sentry.TraceService,
      deps: [Router],
     },
     {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
     },
     {
      provide: Window, useValue: window
     }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    tracesSampleRate: 1.0,
  });
