import { Component, OnInit } from '@angular/core';
import { finalize, firstValueFrom } from 'rxjs';
import { IAsset, ILandSaleAssetMetadata } from 'src/app/v2/shared/models';
import { IFreeLandPreMintAssetMetadata } from 'src/app/v2/shared/models/assets/asset-types/free-land-pre-mint-metadata';
import { ServiceResultStatus } from 'src/app/v2/shared/services';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../../shared/services/account.service';
import { XSpectarVerseService } from 'src/app/v2/shared/services/xSpectarVerse.service';
import { DatePipe } from '@angular/common'
import { IXspectarVerseAccount } from 'src/app/v2/shared/models/xSpectarVerse/IXspectarVerseAccount';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.scss']
})
export class LandComponent implements OnInit {
  Object = Object;
  landSaleAssets: IAsset[] = [];

  sortedCollectionLandSaleAssets: any;
  freePreMintLandSaleAssets: IAsset[] = [];
  finalLandPlotAssets: IAsset[] = [];
  isLoading : boolean = false;
  totalPlots = 0;
  noLand: boolean = true;

  xSpectarAccount: IXspectarVerseAccount | undefined;

  constructor(private readonly accountService: AccountService, private _xSpectarVerseService: XSpectarVerseService, public datepipe: DatePipe) { }

  async ngOnInit(): Promise<void> {

    this.isLoading = true;

    var accountAssetsResult = await firstValueFrom(this.accountService.getAccountAssets(true));
    if (!accountAssetsResult || accountAssetsResult.status != ServiceResultStatus.Success || !accountAssetsResult.data) {
      return;
    }

    this.landSaleAssets = accountAssetsResult.data!.filter(x => {
      return x.assetSeries.assetCollection?.assetCollectionGuid === environment.landSalePlotsAssetCollectionGuid
        && (x.assetSeries.assetSeriesGuid != environment.freeLandSeriesFromNftPreMintGuid
        && x.assetSeries.assetSeriesGuid != environment.finalLandSeriesFromPackagePreMintGuid
        )
    });

    this.landSaleAssets.sort(this.landSaleAssetSort);
    this.finalLandPlotAssets = accountAssetsResult.data.filter(x => x.assetSeries.assetSeriesGuid === environment.finalLandSeriesFromPackagePreMintGuid);
    this.freePreMintLandSaleAssets = accountAssetsResult.data.filter(x => x.assetSeries.assetSeriesGuid === environment.freeLandSeriesFromNftPreMintGuid);


    // Calc total plots
    this.totalPlots += (this.finalLandPlotAssets.length);

    if (this.freePreMintLandSaleAssets.length > 0){
      this.freePreMintLandSaleAssets.forEach(e => {
        this.totalPlots += (e.assetMetadata as IFreeLandPreMintAssetMetadata).Amount;
      });
    }

    if (this.landSaleAssets.length > 0){
      this.landSaleAssets.forEach(e => {
        this.totalPlots += (e.assetMetadata as ILandSaleAssetMetadata).landVoucherQuantity;
      })
    }

    this.noLand = (this.totalPlots == 0);

    this.xSpectarAccount = await this._xSpectarVerseService.getExspectarAccountDetails()

    this.isLoading = false;

  }

  replaceLandSalePackageNameForCSS(name: string) {
    return name.replace(/ /g, '-').toLowerCase();
  }

  private landSaleAssetSort(landSaleAssetA: IAsset, landSaleAssetB: IAsset) {
    const xMetaData = landSaleAssetA.assetMetadata as ILandSaleAssetMetadata;
    const yMetaData = landSaleAssetB.assetMetadata as ILandSaleAssetMetadata;

    if (!xMetaData.landVoucherQuantity) return -1;
    if (!yMetaData.landSaleLevel) return -1;
    return yMetaData.landVoucherQuantity - xMetaData.landVoucherQuantity;
  }

  getVoucherPlotTotal(voucher : IAsset) : number{
    return (voucher.assetMetadata as ILandSaleAssetMetadata).landVoucherQuantity
  };
  getFreePlotTotal(voucher : IAsset) : number{
    return (voucher.assetMetadata as IFreeLandPreMintAssetMetadata).Amount
  };
  toLocalDate(date: Date) {
    return this.datepipe.transform(date, 'yyyy MMMM dd HH:mm');
  }
  voucherStatus(voucher : IAsset) : string {

    var asLower = voucher.assetStatusGuid.toLowerCase();

    if (asLower == "6e8776ba-1a72-4f56-b743-0881b47b73d0") {
      return "Active"
    } else if (asLower == "30e7635a-5bda-4767-9691-629cc8bbdc35") {
      return "refunded";
    } else if (asLower == "73cdf89f-7669-43b3-9708-f5ff63eeaf97") {
      return "sold";
    } else if (asLower == "84e96722-6cec-489e-b1e9-3d9740919569") {
      return "transfered";
    } else if (asLower == "7138a722-d5cb-4adf-92ce-80ab085f23b2") {
      return "blocked";
    } else if (asLower == "519793bc-9d46-4646-9885-f99465b02d0a") {
      return "redeemed";
    } else if (asLower == "8409a7f2-16a3-4983-91ba-09ba5dd7b834") {
      return "redeeming";
    } else if (asLower == "50bc9d06-d25c-4d9f-bc52-8518d1c5962c") {
      return "redeem failed";
    } else if (asLower == "3046d081-e6e5-4329-82e3-3dd02a10943b") {
      return "cancelled";
    } 
	
    return "";

  }

}
