import { EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from 'src/environments/environment';
import { IRegionSummary } from "../models/xSpectarVerse/IRegionSummary";
import { HttpClient } from "@angular/common/http";
import { ServiceResult, mapServiceResult } from "./results/service-result";
import { IZoneSummary } from "../models/xSpectarVerse/IZoneSummary";
import { IParcelSummary } from "../models/xSpectarVerse/IParcelSummary";
import {  Subject, Subscription, firstValueFrom, tap } from "rxjs";
import { ServiceResultStatus } from "./results/service-result-status";
import { IXspectarVerseApiToken } from "../models/xSpectarVerse/IXspectarVerseApiToken";
import { IXspectarVerseAccount } from "../models/xSpectarVerse/IXspectarVerseAccount";
import { ILandVoucherSummary } from "../models/xSpectarVerse/ILandVoucherCollectionSummary";
import { IRedeemLandVoucherRequest } from "../models/xSpectarVerse/IRedeemLandVoucherRequest";
import { IAccountParcelAllocationSummary } from "../models/xSpectarVerse/IAccountParcelAllocationSummary";
import { IAllocateParcelsRequest } from "../models/xSpectarVerse/IAllocateParcelsRequest";
import { SecurityService } from "./security.service";
import { NotificationService } from "./notification.service";
import { NotificationType } from "./notifications";
import { data } from "jquery";
import { IRegistrationIntentRequest } from "../models/IRegistratonIntentRequest";

@Injectable({
    providedIn: 'root',
   })

   export class XSpectarVerseService {

    isSignedIn: boolean = false;
    securityToken: string | undefined;
    account : IXspectarVerseAccount| undefined;

    @Output()
    xSpectarAccountLogedin: Subject<IXspectarVerseAccount | undefined> = new Subject<IXspectarVerseAccount | undefined>();

    @Output()
    showUiLocked: Subject<boolean> = new Subject<boolean>();

    @Output()
    allocateError: Subject<string> = new Subject<string>();


    subscriptions : Subscription[] = [];

    constructor(private httpClient: HttpClient, private notificationService: NotificationService) {

        this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
            this.account = undefined;
            this.xSpectarAccountLogedin.next(undefined);
        }));

    }
    ngOnDestroy() {

        this.subscriptions.forEach(e => e.unsubscribe());

    }

    async getXspectarTokenKey() {

        var result = await firstValueFrom(this.httpClient.get<ServiceResult<IXspectarVerseApiToken>>(`${environment.xSpectarVerseApiUrl}security/signInFromFuelStack`));

        localStorage.setItem("xspectar_signToken", result.data!.accessToken)
        this.isSignedIn = true;
        this.securityToken = result.data!.accessToken;

    }

    async getExspectarAccountDetails(forceReload: boolean = false) : Promise<IXspectarVerseAccount | undefined> {

        if (!this.isSignedIn) {
            await this.getXspectarTokenKey();
        }

        if (this.account && !forceReload) {
            this.xSpectarAccountLogedin.next(this.account);
            return this.account;
        }

        var result = await firstValueFrom(this.httpClient.get<ServiceResult<IXspectarVerseAccount>>(`${environment.xSpectarVerseApiUrl}account/current/details`));

        if (result.status === ServiceResultStatus.Success) {
            this.account = result.data;
        }

        this.xSpectarAccountLogedin.next(this.account!);
        return this.account;

    }
    
    async getAllLandVoucherDataDetails() : Promise<ILandVoucherSummary[] | undefined> {

        if (!this.isSignedIn) {
            await this.getXspectarTokenKey();
        }

        var result = await firstValueFrom( this.httpClient.get<ServiceResult<ILandVoucherSummary[]>>(`${environment.xSpectarVerseApiUrl}account/current/asset/LandPlotVouchers`));

        if (result.status === ServiceResultStatus.Success) {
            return result.data
        }

        return undefined;

    }
    
    async redeemLandVouchers(CashInRequestData: IRedeemLandVoucherRequest[]) : Promise<string[] | undefined> {

        if (!this.isSignedIn) {
            await this.getXspectarTokenKey();
        }

        this.showUiLocked.next(true);

        var result = await firstValueFrom(this.httpClient.put<ServiceResult<string[]>>(`${environment.xSpectarVerseApiUrl}account/current/asset/LandPlotVouchers/redeem`, CashInRequestData));
        
        this.showUiLocked.next(false);

        if (result.status === ServiceResultStatus.Success) {
            return result.data
        }

        return undefined;

    }

    async getAllParcelAllicationDetails(landGuid: string) : Promise<IAccountParcelAllocationSummary[] | undefined> {

        if (!this.isSignedIn) {
            await this.getXspectarTokenKey();
        }

        var result = await firstValueFrom( this.httpClient.get<ServiceResult<IAccountParcelAllocationSummary[]>>(`${environment.xSpectarVerseApiUrl}XVerseLand/GetAccountsParcelAllocationDetails/${landGuid}`));

        if (result.status === ServiceResultStatus.Success) {
            return result.data
        }

        return undefined;

    }

    async allocateParcels(landGuid: string, regionGuid : string, parcels : IParcelSummary[]) : Promise<IParcelSummary[] | undefined> {

        this.showUiLocked.next(true);

        if (!this.isSignedIn) {
            await this.getXspectarTokenKey();
        }

        var request = {
            landGuid : landGuid,
            regionGuid : regionGuid,
            parcelGuids : parcels.map(e => e.parcelGuid)
        } as IAllocateParcelsRequest

        var data = undefined;
        try {
            var result = await firstValueFrom(this.httpClient.post<ServiceResult<IParcelSummary[]>>(`${environment.xSpectarVerseApiUrl}XVerseLand/AllocateParcels`, request));
        
            if (result.status === ServiceResultStatus.Success) {
                data = result.data
            }
    
        }
        catch (e : any) {
            this.allocateError.next(e.error.message);     
        }

        this.showUiLocked.next(false);
        return data;

    }
    async unAllocateParcels(parcelAllocationGuid: string) {

        this.showUiLocked.next(true);

        if (!this.isSignedIn) {
            await this.getXspectarTokenKey();
        }

        var result = await firstValueFrom(this.httpClient.delete<ServiceResult<IParcelSummary>>(`${environment.xSpectarVerseApiUrl}XVerseLand/UnallocateParcel/${parcelAllocationGuid}`));
        
        this.showUiLocked.next(false);

        if (result.status === ServiceResultStatus.Success) {
            return result.data
        }

        return undefined;

    }

    getLandRegions(landGuid: string) {
        return this.httpClient.get<ServiceResult<IRegionSummary[]>>(`${environment.xSpectarVerseApiUrl}XVerseLand/Land/${landGuid}/Regions`);
    }
    getRegionZones(regionGuid: string) {
        return this.httpClient.get<ServiceResult<IZoneSummary[]>>(`${environment.xSpectarVerseApiUrl}XVerseLand/Region/${regionGuid}/Zones`);
    }
    getZoneParcels(zoneGuid: string) {
        return this.httpClient.get<ServiceResult<IParcelSummary[]>>(`${environment.xSpectarVerseApiUrl}XVerseLand/Zone/${zoneGuid}/Parcels`);
    }

    /**
 * Verifies a link code on the XspectarVerse Api
 * @param linkCode The link code to verify
 * @returns A promise that resolves with a ServiceResult<string>
 */
    async verifyLinkCode(linkCode: string): Promise<ServiceResult<string>> {
        try {
            // If not signed in, get token key from xspectarVerse Api
            if (!this.isSignedIn) {
                await this.getXspectarTokenKey();
            }

            const result = await firstValueFrom(
                this.httpClient.get<ServiceResult<string>>(
                    `${environment.xSpectarVerseApiUrl}security/LinkCode/Verify/${linkCode}`
                )
            );

            return result;
        } 
        catch (error: any) { 
            const result = new ServiceResult<string>();
            result.status = error.error.status;
            result.message = JSON.stringify(error.error.message); // Accessing customError.message
            return result;
        }
    }

      /**
 * Sends Registration Intent Details to Xspectarverse
 * @param linkCode The link code to verify
 * @returns A promise that resolves with a ServiceResult<string>
 */
      async Register(request: IRegistrationIntentRequest): Promise<ServiceResult> {
        try {
            var result = await firstValueFrom(this.httpClient.post<ServiceResult>(`${environment.xSpectarVerseApiUrl}RegisterIntent`, request));
            return result;
        } 
        catch (error: any) { 
            const result = new ServiceResult();
            result.status = error.error.status;
            result.message = JSON.stringify(error.error.message);
            return result;
        }
    }
}
