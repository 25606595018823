<div class="qrCode" *ngIf="qrCodeImgData">

  <a *ngIf="walletType == QrCodeWalletTypes.Xumm" class="qrCode__link" [href]="signInQrUrl">
    <div class="qrCode__code">
      <img class="qrCode__img" [src]="qrCodeImgData" />
    </div>
    <p  *ngIf="showFooterMessage">
      <span>{{footerMessage}}</span>
    </p>
  </a>

  <button class="button button--tertairy-alt" type="button" (click)="emitCancel()">
    Cancel
  </button>
</div>
