export enum NotificationType {
  SignInInitialized = 'signin-initialized',
  SignInApproved = 'signin-approved',
  SignInRejected = 'signin-rejected',
  SignOut = 'signout',

  PurchaseAwaitingConfirmation = 'purchase-awaiting',
  PurchaseFailed = 'purchase-failed',
  PurchaseCompleted = 'purchase-completed',
  PurchaseProcessing = 'purchase-processing',

  PaymentComplete = 'payment-complete',
  PaymentFailed = 'payment-failed',
  PaymentInstrumentAdded = 'payment-instrument-added',
  PaymentInstrumentFailed = "payment-instrument-failed",

  AcceptOfferAwaitingConfirmation = 'accept-offer-awaiting',
  AcceptOfferComplete = 'accept-offer-complete',
  AcceptOfferFailed = 'accept-offer-failed',

  AddCryptoWalletComplete = "add-crypto-wallet-complete",
  AddCryptoWalletFailed = "add-crypto-wallet-failed",
  AddCryptoWalletFailedMessage = "add-crypto-wallet-failed-message",

  AcceptBrokeredOfferAwaitingConfirmation = 'accept-brokered-offer-awaiting',
  AcceptBrokeredOfferComplete = 'accept-brokered-offer-complete',
  AcceptBrokeredOfferFailed = 'accept-brokered-offer-failed',


  CreateOfferComplete = 'create-offer-complete',
  CreateOfferFailed = 'create-offer-failed',

  CancelOfferComplete = 'cancel-offer-complete',
  CancelOfferFailed = 'cancel-offer-failed',

  

  XrplOffersUpdate = 'xrpl-offers-update',
  XrplNftOwnerUpdate = "xrpl-nft-updated-owners",

  ListingCurrencySelected = 'listing-currency-selected'
}
